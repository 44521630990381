<template>
  <div class="main-card">
    <h4 class="boxTitle">افزایش موجودی کیف پول</h4>
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow v-if="successPayment">
      <h4 class="faktorTitle">پرداخت شما با موفقیت انجام شد</h4>
      <div class="faktorBox">
        <h4>کد تراکنش :</h4>
        <h3>{{ transactionSuccess.number }}</h3>
      </div>
      <div class="faktorBox">
        <h4>مبلغ تراکنش :</h4>
        <h3 style="direction: rtl">
          {{ ToRial(transactionSuccess.amount) }} ریال
        </h3>
      </div>
      <div class="faktorBox">
        <h4>تاریخ و ساعت :</h4>
        <h3>{{ transactionSuccess.createDateTime }}</h3>
      </div>
      <div class="faktorBox">
        <h4>کد رهگیری بانک :</h4>
        <h3>{{ transactionSuccess.bankInfoTrackingCode }}</h3>
      </div>
      <button class="faktorBtn" @click="backToPaymentBox()">
        بازگشت به صفحه افزایش موجودی
      </button>
    </CRow>
    <CRow v-else>
      <CCol v-if="customOfferCodeList.length > 0">
        <ul class="disCountList">
          <li
            :class="
              item.state ? 'disCountItem' : 'disCountItem disabledDiscount'
            "
            v-for="(item, index) in customOfferCodeList"
            :key="index"
          >
            <i class="fa fa-magic" aria-hidden="true"></i>
            <h6>کد تخفیف {{ item.percentage }}%</h6>
            <h2 @click="computingDiscountAmount(item)">{{ item.code }}</h2>
          </li>
        </ul>
      </CCol>
      <CCol md="12">
        <CInput
          type="text"
          placeholder="مبلغ افزایش موجودی را وارد کنید"
          v-model="paymentAmount"
        />
      </CCol>
      <CCol md="12" class="mb-3 mt-2">
        <div class="inventoryInfo">
          <h4>مبلغ قابل پرداخت :</h4>
          <h3>{{ paymentAmount !== "" ? ToRial(paymentAmount) : 0 }} ریال</h3>
        </div>
        <div class="inventoryInfo">
          <h4>مبلغ تخفیف :</h4>
          <h3>{{ ToRial(discountAmount) }} ریال</h3>
        </div>
      </CCol>
      <CCol md="12">
        <CButton
          class="y-center-g-5 justify-content-center"
          color="success"
          block
          @click="getIncreseInventoryInfo()"
        >
          <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          <span>
            {{
              paymentAmount !== ""
                ? `شارژ کیف پول  ${ToRial(paymentAmount)} ریال`
                : "مبلغ را وارد کنید"
            }}
          </span>
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { domainName } from "../../../constants/config";

export default {
  name: "accountIncreaseInventory",
  props: ["currentAccountId"],
  data() {
    return {
      loading: true,
      loadingText: "",
      customOfferCodeList: [],
      discountAmount: 0,
      discountId: null,
      paymentAmount: "",
      dicCountId: null,
      successPayment: false,
      RefId: "",
      ResCode: 0,
      SaleOrderId: 0,
      SaleReferenceId: 0,
      CardHolderInfo: "",
      formRequest: "",
      transactionSuccess: {
        number: "11223344555",
        amount: "10,000,000",
        createDateTime: "1400/01/10 13:15",
        bankInfoTrackingCode: "1236456734",
      },
    };
  },
  methods: {
    ...mapActions("financialUser", [
      "UserAccountCustomOfferCode",
      "UserIncreseInventoryAccount",
      "GetSuccessTransationWithId",
    ]),
    async getAccountCustomOfferCode() {
      this.loading = true;
      this.loadingText = "دریافت لیست کدهای تخفیف کیف پول شما";
      let result = await this.UserAccountCustomOfferCode();
      if (result.succeeded) {
        this.customOfferCodeList = [];
        result.data.forEach((value, index) => {
          if (value.state == false) {
            this.customOfferCodeList.push({
              id: value.id,
              percentage: value.percentage,
              code: value.code,
              description: value.description,
              used: value.used,
              state: true,
            });
          }
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
    async getIncreseInventoryInfo() {
      this.loading = true;
      this.loadingText = "دریافت اطلاعات درگاه پرداخت";
      let result = await this.UserIncreseInventoryAccount({
        accountId: this.currentAccountId,
        amount: this.paymentAmount,
        disCountId: this.discountId,
        callBackUrl: `${domainName}#/userAccountManagement`,
      });
      if (result.succeeded) {
        window.location.href = result.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در اتصال به درگاه؛با پشتیبانی تماس بگیرید",
          // text: result.message,
          type: "error",
        });
        this.loading = false;
      }
    },
    ToRial(str) {
      str = str.toString().replace(/,/g, "");
      let objRegex = new RegExp("(-?[0-9]+)([0-9]{3})");
      while (objRegex.test(str)) {
        str = str.replace(objRegex, "$1,$2");
      }
      return str;
    },
    async bindSuccessPaymentAmount(id_get, factorId) {
      this.loading = true;
      this.loadingText = "دریافت اطلاعات پرداخت";
      let result = await this.GetSuccessTransationWithId({
        factId: factorId,
        transId: "",
        idGet: id_get,
      });
      console.log("ree", result);
      if (result.succeeded) {
        this.$notify({
          title: "پیام سیستم",
          text: ` کیف پول شما شارژ شد<br /><br />`,
          type: "success",
        });
        this.transactionSuccess.number = result.data.number;
        this.transactionSuccess.amount = result.data.amount;
        this.transactionSuccess.createDateTime = result.data.createDate;
        this.transactionSuccess.bankInfoTrackingCode =
          result.data.bankTrackingCode;
        this.successPayment = true;
        this.$emit("refreshForm");
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
    async backToPaymentBox() {
      this.successPayment = false;
      await this.getAccountCustomOfferCode();
    },
    computingDiscountAmount(item) {
      if (this.paymentAmount > 0 && this.paymentAmount != null) {
        this.discountAmount = (this.paymentAmount * item.percentage) / 100;
        this.paymentAmount = this.paymentAmount - this.discountAmount;
        item.state = false;
        item.code = "استفاده شده";
        this.discountId = item.id;
        this.$notify({
          title: "پیام سیستم",
          text: `کد تخفیف اعمال شد <br /><br />`,
          type: "success",
        });
      } else {
        this.$confirm({
          message: "ابتدا مبلغ افزایش موجودی را وارد کنید.",
          button: {
            ok: "متوجه شدم!",
          },
        });
      }
    },
  },
  mounted() {
    this.getAccountCustomOfferCode();
    if (this.$route.query.requestId != null) {
      if (this.$route.query.Status == "OK") {
        this.bindSuccessPaymentAmount(
          this.$route.query.Authority,
          this.$route.query.requestId
        );
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: ` پرداخت وجه انجام نشد با پشتیبانی تماس بگیرید  <br /><br />`,
          type: "error",
        });
      }
    }
  },
};
</script>

<style scoped>
.boxTitle {
  width: 100%;
  text-align: center;
  margin: 10px 0 10px 0;
  height: 3vh;
  font-size: 1rem;
  padding-top: 3px;
}

.disCountList {
  float: right;
  list-style: none;
  width: 100%;
  background-color: #e7e7e7;
  padding: 0 3px 0 3px;
  min-height: 30vh;
  height: 30vh;
  overflow-y: auto;
}

.disCountItem {
  float: right;
  width: 100%;
  height: 30px;
  margin: 6px 0 0 0;
  padding-right: 8px;
  padding-top: 4px;
  background-color: #8679ff26;
  border: 1px solid #8679ff78;
  border-right: 4px solid #8679ff;
}
.disCountItem i {
  float: right;
  color: #fff;
  background-color: #8679ff;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 5px;
  border-radius: 100px;
  font-size: 1rem;
  margin: -3px -2px 0 6px;
}
.disCountItem h6 {
  float: right;
  font-size: 0.8rem;
  font-weight: bold;
  color: #606060;
  margin: 4px 11px 3px 7px;
}
.disCountItem h2 {
  font-size: 1rem;
  float: left;
  margin-left: 7px;
  font-weight: 700;
  border-bottom: 1px solid #2188f3;
  color: #2188f3;
  cursor: pointer;
}

.disCountInput {
  float: right;
  width: 88%;
  border: none;
  color: #000;
  height: 3.1vh;
  margin: 0;
  outline: none;
  border: 1px solid rgb(20, 114, 255);
  margin-top: 2%;
}
.disCountBtn {
  float: right;
  border: none;
  background-color: rgb(20, 114, 255);
  color: #fff;
  cursor: pointer;
  height: 3.1vh;
}
.increaseInventory {
  float: right;
  width: 100%;
  border: none;
  color: #000;
  height: 3.1vh;
  margin: 0;
  outline: none;
  border: 1px solid green;
  margin-top: 1%;
}
.payBtn {
  border: none;
  background-color: green;
  color: #fff;
  width: 95%;
  margin-top: 2%;
  cursor: pointer;
  margin-right: 3%;
}

.inventoryInfo {
  width: 48%;
  float: right;
  border: 1px solid #bbb;
  border-radius: var(--border-radius);
  box-shadow: 0 0 2px 0 #bbb;
  margin-right: 4px;
}
.inventoryInfo h4 {
  width: 100%;
  background-color: #adadadbb;
  color: #474747;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  margin: 0;
}
.inventoryInfo h3 {
  float: right;
  clear: both;
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding: 4px 4px;
  color: #3a3a3a;
  margin: 0;
}

.faktorTitle {
  width: 100%;
  text-align: center;
  font-size: 15px;
  background-color: #95fbb7;
  border: 1px solid #00812b;
  padding: 4px;
}
.faktorBox {
  width: 100%;
  float: right;
}
.faktorBox h4 {
  width: 40%;
  float: right;
  clear: right;
  background-color: #bbb;
  font-size: 14px;
  padding: 2px 2px;
  text-align: left;
  color: #262626;
  border: 1px solid #00b300;
}
.faktorBox h3 {
  float: right;
  width: 60%;
  font-size: 15px;
  background-color: #30fd76;
  padding: 3px;
  font-weight: bold;
  direction: ltr;
}
.faktorBtn {
  width: 100%;
  border: none;
  background-color: #2188f3;
  float: right;
}
.disabledDiscount {
  pointer-events: none;
  opacity: 0.6;
}
</style>
